.contactinfo {
  padding: 1rem;
  background-color: rgb(223, 213, 213);
  margin: 2rem auto;
  width: 50rem;
  max-width: 95%;
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.contactinfo__items {
  font-size: 1rem;
  font-weight: bold;
  color: black;
  background-color: whitesmoke;
  border: 3px solid rgb(17, 19, 136);
  padding: 0.5rem;
  border-radius: 12px;
}

.conContainer {
  padding-top: 2rem; 
  padding-bottom: 2rem; 
}

.conContainer__p{
  text-align: center;
}

.conitem {
  padding: 5px;
  text-align: center;
  color: white;
  border-radius: 10px;
}

.conDetailsContainer {
  padding: 0.5rem;
}
