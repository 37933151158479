.skillz__Col {
    font-size: 1rem;
    border: 5px solid white;
    padding: 0.5rem;
    border-radius: 12px;
    text-align: center;
  }
  
  .skillz__Col2 {
    padding: 0.5rem;
    border-radius: 12px;
  }
    
  .expenses-filter__control {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 0.5rem;
  }
  
  .expenses-filter__label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .expenses-filte__select {
    background-color:whitesmoke;
    font: inherit;
    padding: 0.5rem 3rem;
    font-weight: bold;
    border-radius: 6px;
    padding: 0.5rem;
  }