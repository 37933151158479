.resumepage {
    padding: 1rem;
    margin: 2rem auto;
    width: 100rem;
    max-width: 95%;
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  /* Rounded border */
  hr.rounded {
    border-top: 8px solid rgb(17, 19, 136);
    border-radius: 5px;
  }

  .center {
    text-align: center;
    border: 3px solid rgb(17, 19, 136);
  }