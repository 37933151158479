
.eduCenter {
  padding: 10px;
  text-align: center;
  background: #000930;
  color: white;
  font-size: 30px;
  border-radius: 30px;
  }
  img {
    width: 100%;
    height: auto;
  }

  .education__items {
    max-width: 30%;
    font-size: 1rem;
    font-weight: bold;
    color: black;
    background-color:whitesmoke;
    border: 3px solid rgb(17, 19, 136);
    padding: 0.5rem;
    border-radius: 12px;
    text-align: center;
  }

  .eduContainer{
    padding: 0.5rem;
  }

  .eduContainer__item{
    padding: 0.5rem;

  }

  .eduContainer__p{
    text-align: center;
  }