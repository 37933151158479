.centerText {
  text-align: center;
}

.expContainer {
  padding: 0.5rem;
}

.expCenter {
  padding: 10px;
  text-align: center;
  background: #000930;
  color: white;
  font-size: 30px;
  border-radius: 30px;
}

.expitem {
    padding: 5px;
    text-align: center;
    background: #2097a7;
    color: white;
    border-radius: 10px;
  }

  .expDetailsContainer {
    padding: 0.5rem;
    background: whitesmoke;
    border: 1px solid rgb(0, 0, 0);
  }
