.center {
    text-align: center;
    border: 3px solid rgb(17, 19, 136);
    border-radius: 12px;
  }

  .header {
    padding: 30px;
    text-align: center;
    background: #0831eb;
    color: white;
    font-size: 30px;
  }